import "./hero-statement.scss";

import cn from "clsx";
import {
  CtaLink,
  CtaLinkProps,
} from "design-system/components/primitives/cta-link/cta-link";
import {
  CtaList,
  CtaListProps,
} from "design-system/components/primitives/cta-list/cta-list";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import {
  QuoteCardContent,
  QuoteCardContentProps,
} from "design-system/components/primitives/quote-card-content/quote-card-content";
import { Theme } from "design-system/utils/theme";

import { ReactNode, useRef } from "react";
import {
  Alignment,
  EditAttributes,
  MediaAlignment,
} from "design-system/types/types";

export interface HeroStatementProps {
  ctaList?: CtaListProps;
  button?: CtaLinkProps;
  media?: MediaAssetProps;
  subhead?: ReactNode;
  title?: string;
  theme?: Theme;
  isCard?: boolean;
  quote?: QuoteCardContentProps;
  align?: Exclude<Alignment, "center">;
  mediaAlignment?: MediaAlignment;
  HeadingLevel?: "h2" | "h3";

  editAttributes?: {
    title?: EditAttributes;
    subhead?: EditAttributes;
  };
}

/**
 * ## See it in use on...
 * - The [MBA homepage](/story/example-pages-landing-pages-mba-homepage--story)
 *
 * ### Card Style
 * - **`id: CB-006-003-01`**
 * - **`data-region: cb__hero-statement__card__button`**
 *
 *
 * ### Flat Style
 * - **`id: CB-006-004-02`**
 * - **`data-region: cb__hero-statement__flat__button`**
 */
export function HeroStatement({
  ctaList,
  button,
  media,
  subhead,
  theme = "white",
  title,
  isCard = true,
  quote,
  align = "full",
  mediaAlignment = "left",
  HeadingLevel = "h2",
  editAttributes,
}: HeroStatementProps) {
  const kalturaRef = useRef<HTMLButtonElement>(null);
  const hasCta = Boolean(ctaList && button);

  const cardType = cn(
    "hbs-hero-statement__card",
    hasCta && "hbs-hero-statement__card--cta",
    !hasCta && "hbs-hero-statement__card--text-only",
  );

  const componentClass = isCard
    ? "hbs-hero-statement hbs-hero-statement--card"
    : "hbs-hero-statement hbs-hero-statement--flat";

  const playKalturaVideo = () => {
    if (kalturaRef.current) {
      kalturaRef.current.click();
    }
  };

  const presentationalVariant = isCard ? "card" : "flat";

  const contentVariants = [
    !quote && button && "button",
    !quote && ctaList && "linklist",
    quote && "quote",
    !hasCta && !quote && "text-only",
    media?.kaltura && "kaltura",
  ]
    .filter(Boolean)
    .join("__");

  const dataRegion = `cb__hero-statement__${presentationalVariant}${
    contentVariants && `__${contentVariants}`
  }`;

  return (
    <div
      className={`hbs-component--hero-statement hbs-global-align-${align}`}
      data-region={dataRegion}
    >
      <div
        className={cn(
          componentClass,
          `hbs-hero-statement--image-${mediaAlignment}`,
        )}
        data-theme={theme}
      >
        <div className="hbs-hero-statement__content">
          <div className={cardType}>
            <div className="hbs-hero-statement__card-inner">
              {quote ? (
                <QuoteCardContent {...quote} />
              ) : (
                <>
                  {title && (
                    <HeadingLevel
                      {...editAttributes?.title}
                      className="hbs-hero-statement__title"
                    >
                      {title}
                    </HeadingLevel>
                  )}

                  {subhead && (
                    <div
                      {...editAttributes?.subhead}
                      className="hbs-hero-statement__subhead"
                    >
                      {subhead}
                    </div>
                  )}

                  {media?.kaltura && (
                    <div className="hbs-hero-statement__trigger-container">
                      <button
                        type="button"
                        className="hbs-hero-statement__modal-trigger"
                        onClick={media?.kaltura && playKalturaVideo}
                        aria-hidden
                        tabIndex={-1}
                      >
                        <div className="hbs-hero-statement__kaltura-text">
                          <span className="hbs-hero-statement__kaltura-text-prompt">
                            Play
                          </span>{" "}
                          {media?.kaltura.duration && (
                            <span className="hbs-hero-statement__kaltura-duration">
                              {media?.kaltura.duration}
                            </span>
                          )}
                        </div>
                      </button>
                    </div>
                  )}

                  {(ctaList || button) && !media?.kaltura && (
                    <div className="hbs-hero-statement__cta">
                      {button && (
                        <CtaLink {...button} type="primary-button"></CtaLink>
                      )}

                      {ctaList && <CtaList {...ctaList} />}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {media && (
          <div
            className={cn(
              "hbs-hero-statement__media",
              media?.kaltura && "hbs-hero-statement__media--kaltura",
            )}
          >
            <MediaAsset ref={kalturaRef} {...media} aspectRatio="3/2" />
          </div>
        )}
      </div>
    </div>
  );
}
