import { HeroStatementEntry } from "frontend/contentful/schema/blocks";
import {
  HeroStatement,
  HeroStatementProps,
} from "design-system/components/blocks/hero-statement/hero-statement";
import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { CtaListProps } from "design-system/components/primitives/cta-list/cta-list";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { useContentful } from "frontend/hooks/use-contentful";

interface HeroStatementsOptions {
  headerAbove?: boolean;
}

export const ContentfulHeroStatement = createContentfulComponent<
  HeroStatementEntry,
  HeroStatementsOptions
>(({ entry, options, createEditAttributes }) => {
  const { getEntryUrl, getEntry, getEntries } = useContentful();

  const headerAbove = options?.headerAbove ?? false;

  const props: HeroStatementProps = {
    title: entry.fields.title,
    theme: entry.fields.theme!,
    isCard: entry.fields.style === "card",
    HeadingLevel: headerAbove ? "h3" : "h2",
    subhead: <RenderContentfulRichText document={entry.fields.description} />,
    mediaAlignment: entry.fields.mediaAlignment,

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      subhead: createEditAttributes({ entry, fieldId: "description" }),
    },
  };

  const button = getEntry(entry.fields.button);

  if (button?.fields?.text) {
    props.button = {
      children: button?.fields.text,
      href: getEntryUrl(entry.fields.button),
      "aria-label": button.fields.ariaLabel,
      editAttributes: createEditAttributes({
        entry: button,
        fieldId: "title",
      }),
    };
  }

  if (!button?.fields?.text && entry.fields.links) {
    const ctaList: CtaListProps = { items: [] };
    // only allow one cta link if button is present
    const links = props.button
      ? entry.fields.links.slice(0, 1)
      : entry.fields.links;

    getEntries(links)?.forEach((link) => {
      if (link?.fields?.text) {
        ctaList.items?.push({
          href: getEntryUrl(link),
          children: link.fields?.text,
          "aria-label": link.fields.ariaLabel,
          editAttributes: createEditAttributes({
            entry: link,
            fieldId: "title",
          }),
        });
      }
    });

    props.ctaList = ctaList;
  }

  const mediaAssetProps = createMediaAssetProps(entry.fields.media);
  if (mediaAssetProps) props.media = mediaAssetProps;

  return <HeroStatement {...props} />;
});
